import React from 'react'
import Helmet from 'react-helmet'

import withStyles from '@material-ui/core/styles/withStyles'
import RaisedButton from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'

import '../layouts/css/main-style.css'

import API from '../common/api.js'

const theme = createTheme({
  palette: {
    primary: { main: '#4fc3f7' },
    secondary: { main: '#FFB300', contrastText: '#FAFAFA' }
  }
})

const styles = theme => ({
  whiteText: {
    color: 'white'
  },

  cssOutlinedInput: {
    color: 'white',
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important'
  }
})

class TitleGenerator extends React.Component {
  state = {
    results: []
  }

  componentDidMount () {
    this.handleApi(
      'Blue evening on the cold Lofoten beach. Black stones silently watching blurred water of the ocean. Blue sand reflects the sky.'
    )
  }

  handleSubmit (event) {
    event.preventDefault()
    const text = event.target.desc.value
    this.bind(this.handleApi(text))
  }

  handleApi (text) {
    var that = this
    const keys = text
      .trim()
      .split(' ')
      .join(',')
    that.setState({
      loading: true,
      results: [],
      count: 0
    })

    const stateChange = state => {
      that.setState(state)
    }

    const thenCallback = value => {
      if (!value || value.error) {
        throw `no value, ${JSON.stringify(value)} `
      }
      let results = value.data
        .map(val => {
          return { algorithm: value.algorithm, value: val }
        })
        .filter(e => e)
        .map(e => {
          e.value = e.value
            .split(' ')
            .map(word => {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            })
            .join(' ')
          return e
        })

      that.setState({
        results: that.state.results.concat(results)
      })
    }

    const completeCountCallback = it => {
      that.setState({
        count: that.state.count + 1
      })
      if (that.state.count == 4) {
        that.setState({
          loading: false,
          count: 0
        })
      }
    }

    API.nameIt(keys, 'SpecDesc', stateChange)
      .then(thenCallback)
      .then(completeCountCallback)
    API.nameIt(keys, 'MeansLike', stateChange)
      .then(thenCallback)
      .then(completeCountCallback)
    API.nameIt(keys, 'Synonimer', stateChange)
      .then(thenCallback)
      .then(completeCountCallback)
    API.nameIt(keys, 'Generalier', stateChange)
      .then(thenCallback)
      .then(completeCountCallback)
  }

  render () {
    const { classes } = this.props
    let hasResults = this.state.results && this.state.results.length > 0

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Helmet
            title='ARCPanel:Namer – name your landscape photography'
            meta={[
              {
                name: 'description',
                content:
                  'Landscape photography name generator, a quick way to come up with a great name for your images'
              },
              {
                name: 'keywords',
                content:
                  'photography, landscape photography, landscape, naming, name generator, photography namer generator, artificial intelligence, ai name generator, title generator, landscape photography name generator, landscape photography title generator, namer, landscape photography namer'
              }
            ]}
          >
            <link
              href='https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700'
              rel='stylesheet'
            />
            <link
              rel='stylesheet'
              type='text/css'
              href='//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css'
            />
            <link
              rel='icon'
              type='image/png'
              href='https://arcpanel.averin.photo/res/icon.png'
            />
            <meta
              property='og:site_name'
              content='ARCPanel:Namer – name your landscape photography'
            />
            <meta
              property='og:url'
              content='https://arcpanel.averin.photo/res/mov-opt.gif'
            />
            <meta
              property='og:url'
              content='https://arcpanel.averin.photo/namer'
            />
            <meta
              property='og:image'
              content='https://arcpanel.averin.photo/res/mov-opt.gif'
            />
            <meta property='og:image:type' content='image/gif' />
            <meta property='og:type' content='video.other' />
            <meta property='og:image:width' content='600' />
            <meta property='og:image:height' content='338' />

            <script src='//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js' />
          </Helmet>
          <div className='container-fluid namerPhoto'>
            <nav className='navbar arcpanel banner'>
              <Grid container spacing={8}>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <Grid item container xs={2} alignItems='center'>
                    <img width='50' height='50' src='/res/icon_t.png' />
                  </Grid>
                  <Grid item container xs={3} alignItems='center'>
                    <a href='/'>
                      <Typography
                        variant='h5'
                        component='h5'
                        style={{ color: '#fff' }}
                      >
                        ARCPanel
                      </Typography>
                    </a>
                  </Grid>
                  <Hidden only={['lg', 'xl']}>
                    <Grid item xs={12}>
                      <Typography variant='caption' style={{ color: '#fff' }}>
                        Luminosity masks made easy
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  item
                  xs={4}
                  sm={5}
                  md={4}
                  lg={6}
                >
                  <Hidden only={['xs', 'sm', 'md']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Fast 16-bit luminosity masks
                      </a>
                    </Grid>
                  </Hidden>
                  <Hidden only={['xs', 'lg', 'xl']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Fast
                      </a>
                    </Grid>
                  </Hidden>

                  <Hidden only={['xs', 'sm', 'md']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Variety of processing tools
                      </a>
                    </Grid>
                  </Hidden>
                  <Hidden only={['xs', 'lg', 'xl']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Powerful
                      </a>
                    </Grid>
                  </Hidden>

                  <Hidden only={['xs', 'sm', 'md']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Easy to use
                      </a>
                    </Grid>
                  </Hidden>
                  <Hidden only={['xs', 'lg', 'xl']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Simple
                      </a>
                    </Grid>
                  </Hidden>

                  <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <Grid item xs={4}>
                      <a style={{ color: 'white' }} href='/?code=Namer'>
                        Fast. Powerful. Simple
                      </a>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid
                  container
                  justify='center'
                  alignItems='stretch'
                  item
                  xs={8}
                  sm={4}
                  md={4}
                  lg={3}
                >
                  <Grid container justify='center' item xs={6} sm={6} lg={5}>
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      style={{ margin: 8 }}
                      href='https://www.youtube.com/watch?v=6rG8f9_qrdE'
                      target='_blank'
                    >
                      Watch <br />
                      in action{' '}
                      <img
                        className='socialIcon'
                        width='25'
                        height='25'
                        src='/res/youtube.png'
                      />
                    </Button>
                  </Grid>
                  <Grid container justify='center' item xs={6} sm={6} lg={7}>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      style={{ margin: 8, fontWeight: 'bold' }}
                      href='/?code=Namer'
                    >
                      Try for Free
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </nav>

            <div className='row landing_row'>
              <div className='col' />
              <div className='col-sm-12 col-md-12 col-lg-9 col-xl-8'>
                <Typography
                  variant='h3'
                  component='h3'
                  style={{
                    color: '#fff',
                    margin: 16
                  }}
                >
                  Let AI* name your landscape
                </Typography>
                <form onSubmit={this.handleSubmit.bind(this)}>
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    item
                    xs={12}
                  >
                    <Grid item xs={10} lg={8}>
                      <TextField
                        id='desc'
                        placeholder='Describe your image in 1-3 sentences.'
                        helperText='Try using adjectives. The better you describe your picture – the more accurate names you will get'
                        fullWidth
                        margin='normal'
                        variant='outlined'
                        required
                        multiline
                        FormHelperTextProps={{
                          classes: {
                            root: classes.whiteText
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.whiteText,
                            focused: classes.cssFocused
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <RaisedButton
                        type='submit'
                        variant='contained'
                        size='large'
                        color='secondary'
                        style={{
                          margin: 16
                        }}
                      >
                        Name it!
                      </RaisedButton>
                    </Grid>
                  </Grid>

                  {
                    // <TextField
                    // id="imageurl"
                    // label="(Optional) Image URL"
                    // style={{
                    //     paddingRight: 32,
                    //     margin: 16
                    // }}
                    // fullWidth
                    //     margin="normal"
                    //     helperText="Give us a link to your image so we could make our AI* better"
                    // />
                  }
                </form>
              </div>
              <div className='col' />
            </div>
          </div>

          <div className='container-fluid'>
            {hasResults && (
              <div className='row landing_row blue'>
                <div className='col' />
                <div className='col-sm-8 col-md-8 col-lg-6 col-xl-4'>
                  <List>
                    {this.state.results.map(item => (
                      <ListItem>
                        <ListItemText
                          classes={{ primary: this.props.classes.whiteText }}
                        >
                          {item.value}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              navigator.clipboard.writeText(item.value)
                            }}
                          >
                            Copy
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div className='col' />
              </div>
            )}
            {this.state.loading && (
              <div className='row landing_row blue'>
                <div className='col' />
                <div className='col-1'>
                  <Grid container justify='center' alignItems='center' xs>
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </div>
                <div className='col' />
              </div>
            )}
            <div className='row'>
              <div className='col' />
              <div className='col' />
              <div className='col'>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '8pt'
                  }}
                >
                  * On this page AI stands for Algorithm Implementation
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(TitleGenerator)
